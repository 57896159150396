import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { useLayer } from "src/hooks";
import { LayerTypes } from "src/types/enum";
import styled from "styled-components";

interface LayerGroupingDialogProps {
  open?: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: (name: string) => void;
}

export const LayerGroupingDialog = React.memo(
  (props: LayerGroupingDialogProps) => {
    const { open, loading, onCancel, onConfirm } = props;
    const [name, setName] = useState("");
    const { selectedLayers } = useLayer();
    const includeGroupLayers = selectedLayers.some(
      (layer) => layer.layer_type === LayerTypes.GROUP
    );

    const handleConfirm = useCallback(() => {
      onConfirm(name);
      setName("");
    }, [name, onConfirm]);

    return (
      <Dialog
        aria-labelledby="group-title"
        open={Boolean(open)}
        onClose={onCancel}
      >
        <DialogTitle id="group-title">
          {includeGroupLayers ? "Merge Group" : "Group Layers"}
        </DialogTitle>
        <CustomDialogContent dividers>
          <Box width="300px">
            <TextField
              label="Group Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          {includeGroupLayers ? (
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <Warning
                style={{
                  marginRight: "4px",
                  marginLeft: "8px",
                  fontSize: "20px",
                }}
              />
              Some group properties won&apos;t apply properly.
            </Typography>
          ) : (
            <></>
          )}
        </CustomDialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>

          <Button
            onClick={handleConfirm}
            color="secondary"
            variant="outlined"
            autoFocus
          >
            {loading ? (
              <CircularProgress color="secondary" size={20} />
            ) : (
              "Group"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export const CustomDialogContent = styled(DialogContent)(
  ({ theme }) => `
  padding-right: 8px;
   ${theme.breakpoints.up("sm")} {
    padding-right: 24px;
  }
`
);

export default LayerGroupingDialog;
